
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";
let formSchemeApi = {
    instance: apiHttpClient,
    namespaces:'formSchemeInfo',
    locked: false,
    localStore: {},
    initParams(url, params) {
        if (params.length == 0)
            return url

        url += '?'
        for (let index in params) {
            url += params[index].name + '=' + params[index].value + '&'
        }
        return url.substring(0, url.length - 1)
    },
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },
    getPagedList: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/formSchemeInfo/GetPaged";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },

    getList: async function (params) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetList";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    getAllTree: async function () {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/SysInterface/GetAllTree";
        let options_ = {
            method: "GET",
            url: url_,
            headers: {
                Accept: "application/json",
            },
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }

        return _response.data

    },
    getInfo:async function(infoId){
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/FormSchemeInfo/GetEditById";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, {id:infoId}),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data


    },
    getScheme: async function (id) {
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/FormScheme/GetEditById";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, {id:id}),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    getById: async (itemId) => {
    },
    getByCode: async (code) => {

    },
    async getInstanceForm(schemeId,keyValue){
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/FormScheme/getInstanceForm";
        let options_ = {
            method: "get",
            url: UrlHelper.createGetRequest(url_,{schemeId:schemeId,keyValue:keyValue}) ,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    async saveInstanceForm(schemeId,mainTableId,formData){
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/FormScheme/SaveInstanceForm";
        let options_ = {
            method: "post",
            url: UrlHelper.createGetRequest(url_,{schemeId,mainTableId}) ,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
            },
            data: formData
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data

    },
    async getTreeData(code, parentId) {
        var params = {code: '', parentId: ''}

        if (!!code) {
            params.code = code
        }
        if (!!parentId) {
            params.parentId = parentId
        }
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/SysInterface/GetTreeData";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    async save(item) {
        console.log('???????')
        // /api/services/app/FormSchemeInfo/SaveFormSchemeInfo
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/FormSchemeInfo/SaveFormSchemeInfo";
        console.log('beformClone')
        var content = objectHelper.deepClone(item)
        console.log(item.params)
        content.params = JSON.stringify(item.params)
        console.log(item.params)
        content.headers = JSON.stringify(item.headers)
        console.log(item.params)
        content.fields = JSON.stringify(item.fields)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
    },
            data: content
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    }
}

export default formSchemeApi