<template>
    <a-spin :spinning="spinning">
        <!-- <page-header title="流程任务"></page-header> -->
        <a-row :gutter="8">
            <a-col
              :span="6"
              class="organization-unit-tree-panel"
              style="width: 200px; height: 100%; overflow-x: auto"
            >
                <a-card>
                    <ul class="lr-left-list" id="lr_left_list">
                        <li
                                v-for="(item, index) in todos"
                                @click="initleft(item)"
                                class="lrlg"
                                :class="{ active: item.active }"
                                :key="index"
                                :data-value="item.value"
                        >
                            {{ item.text }}
                        </li>
                    </ul>
                </a-card>
            </a-col>
            <a-col
                    :span="18"
                    class="organization-unit-tree-panel"
                    style="width: calc(100% - 200px)"
            >
                <!-- table -->
                  <!-- <react-table
                      :table-data="tableData"
                      :total-items="totalItems"
                      :showTotal="showTotalFun"
                      :customRow="customRow"
                      tableKey ="taskId"
                      :columns="columns"
                      :hide-row-selection="true"
                      :is-full="true"
                      @emitRefreshData="clearFilterAndRefresh"
                      @emitSortData="updateSortData"
                      @emitSelectChange="updateSelectChange"
                      @emitOnPageChange="updatePageChange"
                      @emitShowSizeChange="updateShowSizeChange"
                  > -->
                  <ele-table
                    :columns="columns"
                    :table-data="tableData"
                    :total-items="totalItems"
                    :is-full="true"
                    :current-page="pageNumber"
                    :hide-row-selection="true"
                    @emitRefreshData="clearFilterAndRefresh"
                    @emitSelectChange="updateSelectChange"
                    @emitOnPageChange="updatePageChange"
                    @emitShowSizeChange="updateShowSizeChange"
                  >
                    <a-row :gutter="8" >
                      <a-col :span="6" class="gutter-row">
                          <a-input
                            :placeholder="l('SearchWithThreeDot')"
                            v-model.trim="filterText"
                          />
                      </a-col>

                      <a-col :span="4" class="gutter-row">
                          <a-button @click="search" type="primary">
                              {{ l("Search") }}
                          </a-button>
                          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                      </a-col>
                       <a-col :span="14" style="text-align: right;">
                          <a-button @click="()=>{
                              this.releaseFormCol.show = true
                          }" type="primary" >
                              <a-icon type="plus" />
                              创建流程
                          </a-button>
                      </a-col>
                  </a-row>
                  </ele-table>
            </a-col>
        </a-row>

        <a-drawer width="300" title="选择要发起的流程" :visible="releaseFormCol.show" placement="right" :mask="true" @close="onReleaseFormClose"  >
            <release-form @whenClose="onReleaseFormClose"></release-form>
        </a-drawer>

        <a-drawer  :maskClosable='false'   destroyOnClose width="800" title="流程详情" :visible="flowContainerCol.show" placement="right" :mask="true" @close="auditDrawClose"  >
            <workflow-container-draw  :code="flowContainerCol.code" :task="flowContainerCol.task"   :mode="flowContainerCol.mode"  @whenClose="auditDrawClose" ></workflow-container-draw>
        </a-drawer>
    </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import workflowtaskApi from "@/shared/service-proxies/workflow/services/workflow-task-proxies";
import auditForm from "@/app/workflow/applications/container/index.vue"
import objectHelper from "../../../shared/helpers/objectHelper";
import workflowApi from "../../../shared/service-proxies/workflow/services/workflow-proxies";

import moment from "moment";
import EleTable from "@/components/ele-table";
import { ListTable } from "@/components";
import Vue from "vue";
import workflowContainerDraw from "@/app/workflow/applications/container/index-draw.vue";
import releaseForm from "./releaseform.vue";
import {initLevel, initEnableMark} from "@/shared/common-service";

export default {
  mixins: [AppComponentBase],
  name: "workflow-scheme-info-index",
  components: { EleTable,releaseForm, workflowContainerDraw},
  data() {
    return {
        flowContainerCol:{
            show:false,
            mode:'',
            task:'',
            code:'',
        },
      curDataItemTree: [],
      todos: [
        { text: "待办任务", value: 2, active: true },
        { text: "已办任务", value: 3 },
        { text: "我的流程", value: 1 },
      ],
      queryModel: {
        parentId: "",
        name: "",
      },
        releaseFormCol:{
           show:false
        },
      spinning: false,
      // 搜索
      filterText: "",
      categoryId: "2",
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      tableKey: "WorkflowSchemeInfoPagedDto",
      columns: [

          {
              title: this.l("标题"),
              dataIndex: "title",
              sorter: true,
              align: "center",
              scopedSlots: { customRender: "title" },
          },
          {
              title: '流程模板',
              dataIndex: "schemeName",
              sorter: true,
              align: "center",
              scopedSlots: { customRender: "schemeName" },
          },
        {
          title: this.l("任务"),
          dataIndex: "taskName",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "taskName" },
        },
        {
          title: this.l("等级"),
          dataIndex: "level",
          sorter: true,
          align: "center",
          customRender: function (text, record, index) {
            if (text) {
              switch (text) {
                case 0:
                  return "普通";
                  break;
                case 1:
                  return "重要";
                  break;
                case 2:
                  return "紧急";
                  break;
                default:
                  return "普通";
                  break;
              }
            }
            return "";
          },
          scopedSlots: { customRender: "level" },
        },
        {
          title: this.l("状态"),
          dataIndex: "enabledMark",
          sorter: true,
          align: "center",
          // fixed: "right",
          customRender: function (text, record, index) {
            console.log(text)
            console.log(record)
            if (record.taskType == 4) {
              if (record.isFinished == 0) {
                if (record.isUrge == "1" && categoryId == "2") {
                  return '催办加急';
                }
                return '运行中';
              } else {
                return '结束';
              }
            }
            if (record.isFinished == 0) {
              if (text == 1) {
                if (record.isUrge == "1" && categoryId == "2") {
                  return '催办加急';
                }
                return '运行中';
              } else if (text == 2) {
                return '草稿';
              } else {
                return '作废';
              }
            } else {
              return '结束';
            }
          },
          scopedSlots: { customRender: "enabledMark" },
        },
        {
          title: this.l("发起者"),
          dataIndex: "createUserName",
          sorter: true,
          align: "center",
          // fixed: "right",
          scopedSlots: { customRender: "createUserName" },
        },
        {
          title: this.l("时间"),
          dataIndex: "createDate",
          sorter: true,
          align: "center",
          // fixed: "right",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "createDate" },
        },
      ],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sord: "desc",
        maxResultCount: 50,
        sidx:'CreationTime',
        skipCount: 0,
      },
      _serviceProxy: "",
      commonService: null,
    };
  },
  created() {

    this.getData();
  },computed:{
        //自定义行行为
        customRow(){
            return  function (record){
                return {
                    props: {},
                    on: { // 事件
                        click:(event)=> {
                            console.log('click!!!')
                            console.log(record)
                            this.showAuditDraw(record)
                        },
                        dblclick: (event) => {
                        },
                        contextmenu: (event) => {
                        },
                        mouseenter: (event) => {
                        },  // 鼠标移入行
                        mouseleave: (event) => {
                        }
                    },
                };
            }.bind(this)} 
    },
  methods: {
    onReleaseFormClose(){
          this.releaseFormCol.show = false
          this.refreshGoFirstPage()
    },
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      let params = {
        filterText: this.filterText,
          //排序类型
          sord: this.request.sord,
          //排序字段
          sidx:this.request.sidx,
        maxResultCount: this.request.maxResultCount,
        pageNumber: this.pageNumber,
        skipCount: this.request.skipCount,
        categoryId: this.categoryId,
      };
      workflowtaskApi
        .getPagedList(params)
        .then((res) => {
          this.tableData = res.items;
          this.spinning = false;
          this.tableData = res.items.map(item => {
            return {
              ...item,
              createDate: moment(item.createDate).format("YYYY-MM-DD"),
              level: initLevel(item.returnLevel),
              enabledMark: initEnableMark(item, item.enabledMark, this.categoryId),
            }
          });
          this.totalItems = res.totalCount;
          console.log(this.request.maxResultCount);
          this.totalPages = Math.ceil(
            res.totalCount / this.request.maxResultCount
          );
          this.pagerange = [
            (this.pageNumber - 1) * this.request.maxResultCount + 1,
            this.pageNumber * this.request.maxResultCount,
          ];
        })
        .catch((data, error) => {
          this.spinning = false;
        });
      return;
    },
    initleft(item) {
      this.$nextTick(function () {
        this.todos.forEach(function (item) {
          Vue.set(item, "active", false);
        });
        Vue.set(item, "active", true);
        this.categoryId = item.value;
        this.getData();
      });
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.request.skipCount = 0;
       setTimeout( this.getData(),2000)
    },
    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 单个删除
     */
    deleteItem(item) {
      console.log(item);
      console.log(item.item.id);
      let obj = {};
      obj.id = item.item.id;
      const body = JSON.stringify(obj);
      this.spinning = true;
      this._serviceProxy
        .changeStatus(body)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
      this._serviceProxy.getToExcelFile().then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result);
      });
    },
      showAuditDraw(task){
         this.flowContainerCol.task = task
         this.flowContainerCol.mode = workflowApi.getContainerMode(this.categoryId)
         this.flowContainerCol.show = true
      },
      auditDrawClose(){
          this.flowContainerCol.show = false
          this.refreshGoFirstPage();
      }
  } 
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}

.table-plus,
.table-delete {
  margin-left: 10px;
}

 .lr-left-list{
    height: 100%;
    background-color: white;
 }

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lr-left-list > li {
  position: relative;
  width: 100%;
  height: 34px;

  line-height: 34px;
  margin-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
  border-left: 3px solid #fff;
}
.lr-left-list > li:hover {
  background-color: #f5f5f5;
  border-left: 3px solid #f5f5f5;
}
.lr-left-list > li.active {
  background-color: #f5f5f5;
  border-left: 3px solid #2c3849;
}
</style>
