<template>
  <a-spin :spinning="spinning"  >
        <a-list   :data-source="schemeList"  >
          <a-list-item slot="renderItem" slot-scope="item"
                       :class="{ active: item.active }"    :key="index"  >
            <a-list-item-meta  >
              <span slot="title">{{ item.name }}</span>
              <div style="display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;text-overflow: ellipsis; width:180px;" slot="description"   >
                {{item.description}}
              </div>

            </a-list-item-meta>
            <a-button type="primary"  size="small" @click="showContainer"  :data-code="item.code"  :data-scheme="item" style="width: 60px;height: 60px;">发起</a-button>
          </a-list-item>
        </a-list>

     <a-drawer :maskClosable='false' destroyOnClose width="800" title="发起流程" :visible="flowContainerCol.show" placement="right" :mask="true" @close="workflowContainerClose"  >
      <workflow-container-draw  :code="targetScheme.code"   mode="create" @whenClose="workflowContainerClose"  ></workflow-container-draw>
    </a-drawer>

  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";

import { message } from "ant-design-vue";
import dataItemApi from "@/shared/service-proxies/dataItem/services/dataItem-proxies";
import sysInterfaceApi from "../../../shared/service-proxies/sysInterface/services/sys-interface-proxies";
import workflowSchemeApi from "../../../shared/service-proxies/workflow/services/workflow-scheme-proxies";

import "@/shared/workflow/zr-workflow-ui.js";
import Bus from "@/shared/bus/bus";
import modalHelper from "../../../shared/helpers/modal/modal-helper";
import sysInterfaceSelectForm from "../../systemManagement/sysInterface/selectForm/index";
import objectHelper from "../../../shared/helpers/objectHelper";
import { CommonServiceProxy } from "@/shared/common-service";
import workflowContainerDraw from "@/app/workflow/applications/container/index-draw.vue";
import { ModalHelper } from "@/shared/helpers";
import Vue from "vue";

export default {
  name: "release-form",
  mixins: [AppComponentBase, ModalComponentBase],
  components:{workflowContainerDraw},
  data() {
    return {
      commonService: null,
      schemeList: [],
      spinning: false,
      // 表单
      formLayout: "horizontal",
      // 选中的权限过滤
      selectedPermission: [],
        //要发起的流程
        targetScheme:{
          code:''
        },
      flowContainerCol:{
         show:false
      }
    };
  },
  mounted() {},
  components: {workflowContainerDraw},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.fullData(); // 模态框必须,填充数据到data字段
    this.getData();
  },
  computed: {
    formItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
          }
        : {};
    },
    buttonItemLayout() {
      const { layout } = this.form;
      return layout === "horizontal"
        ? {
            wrapperCol: { span: 14, offset: 4 },
          }
        : {};
    },
  },
  methods: {
    workflowContainerClose(){
       this.flowContainerCol.show = false
         this.wantToClose()
    },
      wantToClose(){
          this.$emit("whenClose")
      },
     showContainer(e,scheme){
       this.flowContainerCol.show = true
         let targetScheme =  e.target.dataset.code
         console.log(targetScheme)
         this.targetScheme.code =targetScheme
     },
    async getData() {
      let params = {
        userId: 1,
      };

      try {
        let data = await this.commonService.get({
          url: "/api/services/app/WorkflowProcess/GetSchemeInfoList",
          params: params,
        });
        if (data) {
          this.schemeList = data;
        }
      } catch (error) {}
    },
    initCardBox(item) {
      this.$nextTick(function () {
        var active = item.active;
        this.schemeList.forEach(function (item) {
          Vue.set(item, "active", false);
        });
        if (active) {
          Vue.set(item, "active", false);
        } else {
          Vue.set(item, "active", true);
        }
        this.schemeData=item;
      });
    },
    removeItem(e) {
      let target = e.target.dataset.target;
      let index = e.target.dataset.index;
      let temp = this.formModel[target];
      temp = temp.filter((value, i) => {
        return i != index;
      });
      this.formModel[target] = temp;
    },
    cancle() {
      this.close();
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(this.schemeData);
        let data=this.schemeData;
        ModalHelper.create(
          workflowContainer,
          {
            mode: "create",
            ...data,
            formModel: {
              ...data,
            },
          },
          {
            width: "1200px",
            isChange: true,
          }
        ).subscribe((res) => {
          console.log("messageRevecived");
          console.log(res);
          this.refreshGoFirstPage();
        });
      });
    },
    updateSpinning(newV) {
      if (newV === "0") {
        this.spinning = false;
      } else {
        this.spinning = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;

  .anticon {
    margin-right: 10px;
  }
}

.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>

<style src="@/styles/workflow/zr-workflow-ui.css"></style>


<style>
.step-pane-base {
  padding: 10px;
  padding-top: 69px;
}

.lr-alert {
  position: absolute;
  top: 10px;
  left: 0;
  height: 59px;
  width: 100%;
  padding: 0 10px;
}

.lr-alert .alert {
  margin-bottom: 0;
}

.lr-alert .alert > i {
  position: relative;
  top: 1px;
  font-size: 15px;
  padding-right: 5px;
}

.lr-panel {
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding-top: 38px;
}

.lr-panel .panel-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 38px;
}

.lr-panel .panel-body {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 383px 0 15px 0;
}

.lr-form-item {
  position: absolute;
  top: 0;
  left: 0;
  height: 38px;
  width: 100%;
  padding-right: 20px;
}

.wizard {
  z-index: 3;
}

.btn-tool-bar {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.step-gird-pane {
  padding-top: 41px;
}

.btn-tool-bar-left {
  position: absolute;
  left: 5px;
  top: 6px;
  width: 300px;
  height: 28px;
  z-index: 4;
  text-align: left;
}

.main-list {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.card-box {
  position: relative;
  float: left;
  width: 190px;
  height: 60px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 3px;
  margin: 10px 0 0 10px;
  padding-left: 65px;
  padding-top: 4px;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: pointer;
}

.card-box.active {
  border: 1px solid #ff5d5b;
  background: url(/img/selected_red.png) right top no-repeat;
}

.card-box-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 58px;
  border-right: 1px solid #ccc;
}

.card-box-img img {
  width: 59px;
  height: 58px;
}

.card-box-content p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
</style>
